<template>
  <!-- **********inicia stores********** -->
  <v-col cols="12" class="position-relative Stores">
    <!-- arrows -->
    <div class="arrow-left in-stores">
      <v-img
        src="@/assets/icons/arrow-slider.svg"
        @click="changeSlidePrev()"
      ></v-img>
    </div>

    <div class="arrow-right in-stores">
      <v-img
        src="@/assets/icons/arrow-slider.svg"
        @click="changeSlideNext()"
      ></v-img>
    </div>
    <!-- /arrows -->

    <v-row justify="center">
      <v-col cols="auto">
        <v-slide-group
          v-model="slideStores"
          v-bind:show-arrows="true"
          class="slideStores"
          center-active
        >
          <v-slide-item v-for="(store, st) in stores" v-bind:key="st">
            <v-card class="card-stores" elevation="0" tile>
              <v-row class="fill-height" align="center">
                <v-col>
                  <template v-if="store.cover != null">
                    <v-img
                      width="139"
                      class="d-block mx-auto"
                      :src="
                        'https://www.cymcms.actstudio.xyz/' + store.cover.path
                      "
                    ></v-img>
                  </template>
                </v-col>
              </v-row>
            </v-card>
          </v-slide-item>
        </v-slide-group>
      </v-col>
    </v-row>
  </v-col>
  <!-- **********/termina stores********** -->
</template>

<script>
export default {
  data() {
    return {
      //stores
      stores: [],
      slideStores: 0,
    };
  },
  beforeCreate() {
    //fetch para las tiendas
    fetch(
      "https://www.cymcms.actstudio.xyz/api/collections/get/stores?token=aa5f1f62f706c89e9dae0ea5e2400a",
      {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          filter: {},
        }),
      }
    )
      .then((res) => res.json())
      .then((res) => (this.stores = res.entries));
  },
  methods:{
    changeSlideNext: function () {
      this.slideStores++;

      if (this.slideStores >= this.stores.length - 1) this.slideStores = 0;
    },
    changeSlidePrev: function () {
      this.slideStores--;

      if (this.slideStores <= this.stores.length)
        this.slideStores = this.stores.length - 1;
    },
  }
};
</script>
<template>
  <div class="home" v-if="render">
    <v-container fluid class="py-0">
      <v-row>
        <v-col cols="12" class="pa-0 position-relative">
          <Splide :slides="homeSingleton.intro" :options="optionsSlider">
            <SplideSlide
              v-for="(item, i) in homeSingleton.intro"
              v-bind:key="i"
            >
              <v-card class="position-relative" elevation="0">
                <template v-if="item.value.intro_image.image == true">
                  <v-img
                    style="object-fit: cover"
                    :src="
                      'https://www.cymcms.actstudio.xyz/storage/uploads/' +
                      item.value.intro_image.path
                    "
                    class="home__intro-img"
                  >
                  </v-img>
                </template>
                <template v-else-if="item.value.intro_image.video == true">
                  <video
                    width="100%"
                    style="object-fit: cover"
                    :src="
                      'https://www.cymcms.actstudio.xyz/storage/uploads/' +
                      item.value.intro_image.path
                    "
                    class="home__intro-img"
                    :autoplay="true"
                    :muted="true"
                    :playsinline="true"
                    :controls="false"
                    :loop="true"
                  ></video>
                </template>
                <div class="home__content text-center">
                  <div>
                    <v-img
                      :contain="true"
                      :href="item.value.link"
                      :to="'/product' + item.value.link_text"
                      :src="
                        'https://www.cymcms.actstudio.xyz/' +
                        item.value.link_visual_content.path
                      "
                      class="home__content--img"
                    >
                    </v-img>
                  </div>
                </div>
              </v-card>
            </SplideSlide>
          </Splide>
        </v-col>
      </v-row>
    </v-container>

    <v-container>
      <v-row
        justify="center"
        justify-sm="space-between"
        align="center"
        class="mt-5 mb-12"
      >
        <v-col cols="12" sm="auto" class="text-center">
          <span class="titles-big-x bree-serif">Our best sellers</span>
        </v-col>

        <v-col cols="12" sm="auto" class="text-center">
          <router-link to="/products" class="not-text-decoration">
            <span class="continuos-texts-2">See all</span>
          </router-link>
        </v-col>
      </v-row>

      <!-- **********inicia productos********** -->
      <v-row id="products-home" justify="center">
        <v-col cols="12" class="pa-0 position-relative">
          <!-- arrows -->
          <div class="arrow-left in-stores">
            <v-img
              src="@/assets/icons/arrow-slider.svg"
              @click="changeSlidePrevProducts()"
            ></v-img>
          </div>

          <div class="arrow-right in-stores">
            <v-img
              src="@/assets/icons/arrow-slider.svg"
              @click="changeSlideNextProducts()"
            ></v-img>
          </div>
          <!-- /arrows -->

          <v-row justify="center" no-gutters>
            <v-col cols="auto">
              <v-slide-group
                v-model="slideProducts"
                v-bind:show-arrows="true"
                center-active
                class="slideProducts"
              >
                <v-slide-item
                  v-for="(product, prs) in products"
                  v-bind:key="prs"
                >
                  <v-hover v-slot="{ hover }">
                    <v-card
                      elevation="0"
                      class="Product__card-similars--home mx-auto"
                      :to="'/product/' + product.url"
                      :ripple="false"
                      @mouseover="isPaused = true"
                      @mouseleave="isPaused = false"
                    >
                      <v-row no-gutters :class="{ 'on-hover-card': hover }">
                        <v-col class="text-center">
                          <div class="py-5">
                            <template v-if="product.cover != null">
                              <v-img
                                class="d-block mx-auto Product__card-similars__image"
                                contain
                                :src="
                                  'https://www.cymcms.actstudio.xyz/' +
                                  product.cover.path
                                "
                              ></v-img>
                            </template>
                          </div>
                          <v-divider class="my-3 divider-product"></v-divider>
                          <v-card-text>
                            <span
                              class="titles-big"
                              v-bind:class="{
                                'bree-serif':
                                  product.style.name == 'Transitional',
                                pacifico: product.style.name == 'Classic',
                                'montserrat-regular ': product.style.name == 'Modern',
                              }"
                              >{{ product.name }}</span
                            >
                          </v-card-text>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-hover>
                </v-slide-item>
              </v-slide-group>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <!-- **********/termina productos********** -->
      <v-row class="mt-12 Stores" justify="center">
        <v-col cols="12">
          <div class="text-center stores-title">
            <span class="titles-big">{{ homeSingleton.stores_title }}</span>
          </div>
        </v-col>
      </v-row>

      <!-- **********inicia productos tabs********** -->

      <!-- **********inicia productos tabs**********| -->
    </v-container>
    <v-container fluid>
      <Reviews></Reviews>
    </v-container>

    <v-container>
      <v-row class="mt-12">
        <v-col cols="12" class="text-center">
          <span
            class="d-block titles-big-x bree-serif line-height-some-titles-2"
            >Whatever your style is, we've got you furnished</span
          >
          <div class="mt-5">
            <span
              v-for="(style, st) in styles"
              v-bind:key="st"
              v-bind:class="{
                'opacity-item': filter != style.name,
                'opacity-none': styleSelected == st,
                'bree-serif': style.name == 'Transitional',
                pacifico: style.name == 'Classic',
                'montserrat-regular': style.name == 'Modern',
              }"
              @mouseover="filter = style.name"
              @mouseleave="filter = ''"
              @click="
                (pathImg = style.img.path),
                  (styleImg = style.name),
                  (filter = style.name),
                  (styleSelected = st)
              "
              class="continuos-texts-2 cursor-pointer"
            >
              {{ style.name }}
              <span class="px-2 px-md-6" v-show="st < styles.length - 1"
                >|</span
              >
            </span>
          </div>
        </v-col>

        <v-col class="d-sm-none" cols="12" sm="4">
          <template v-for="(stylePic, sp) in styles">
            <template v-if="styleSelected == sp">
              <v-card
                :key="sp"
                class="card-products"
                tile
                elevation="0"
                :to="{ path: '/products', query: { name: stylePic.name } }"
              >
                <v-img
                  class="card-products__image-products"
                  :src="'https://www.cymcms.actstudio.xyz/' + stylePic.img.path"
                ></v-img>
              </v-card>
            </template>
          </template>
        </v-col>
        
        <v-col
          class="d-none d-sm-block"
          cols="12"
          sm="4"
          v-for="(stylePic, sp) in styles"
          v-bind:key="sp"
        >
          <v-card
            class="card-products"
            v-bind:class="{
              'opacity-item': filter != stylePic.name,
              'opacity-none': filter == '',
            }"
            tile
            elevation="0"
            @mouseover="filter = stylePic.name"
            @mouseleave="filter = ''"
            :to="{ path: '/products', query: { name: stylePic.name } }"
          >
            <template v-if="stylePic.img != null">
              <v-img
                class="card-products__image-products"
                :src="'https://www.cymcms.actstudio.xyz/' + stylePic.img.path"
              ></v-img>
            </template>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <!-- **********inicia showroom********** -->
    <v-container fluid class="py-0">
      <v-row class="showroom mb-12 mt-12" align="center">
        <v-col cols="12" sm="6" class="showroom__column1">
          <v-row justify="center">
            <v-col cols="auto" class="d-none d-sm-block">
              <div class="div-center">
                <span
                  class="d-block titles-big-x bree-serif line-height-some-titles"
                  >{{ homeSingleton.showroom_card.showroom_title }}</span
                >
                <p class="continuos-texts-small mt-4">
                  {{ homeSingleton.showroom_card.showroom_description }}
                </p>
                <v-btn
                  color="#FFC979"
                  elevation="0"
                  rounded
                  class="home__content--btn btn-general mt-5"
                  target="_blank"
                  :ripple="false"
                  :to="homeSingleton.showroom_card.showroom_btn_link"
                >
                  <span class="home__content--btn__span continuos-texts-2">
                    {{ homeSingleton.showroom_card.showroom_btn_text }}
                  </span>
                </v-btn>
              </div>
            </v-col>
            <v-col cols="auto" class="d-sm-none text-center">
              <div class="div-center">
                <span
                  class="d-block titles-big-x bree-serif line-height-some-titles"
                  >{{ homeSingleton.showroom_card.showroom_title }}</span
                >
                <p class="continuos-texts-small mt-4">
                  {{ homeSingleton.showroom_card.showroom_description }}
                </p>
                <v-btn
                  color="#FFC979"
                  elevation="0"
                  rounded
                  class="home__content--btn btn-general mt-5"
                  target="_blank"
                  :ripple="false"
                  :to="homeSingleton.showroom_card.showroom_btn_link"
                >
                  <span class="home__content--btn__span continuos-texts-2">
                    {{ homeSingleton.showroom_card.showroom_btn_text }}
                  </span>
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" sm="6" class="showroom__column2 pa-0">
          <template v-if="homeSingleton.showroom_image.image == true">
            <v-img
              class="showroom__image"
              :src="
                'https://www.cymcms.actstudio.xyz/' +
                homeSingleton.showroom_image.path
              "
            ></v-img>
          </template>

          <template v-else-if="homeSingleton.showroom_image.video == true">
            <video
              width="100%"
              style="object-fit: cover"
              class="showroom__image"
              :src="
                'https://www.cymcms.actstudio.xyz/storage/uploads/' +
                homeSingleton.showroom_image.path
              "
              :autoplay="true"
              :muted="true"
              :playsinline="true"
              :controls="false"
              :loop="true"
            ></video>
          </template>
        </v-col>
      </v-row>
    </v-container>
    <!-- **********/termina showroom********** -->

    <v-container class="pt-0 pb-10">
      <v-row>
        <v-col cols="12">
          <div class="text-center px-8">
            <span
              class="d-block titles-big-x bree-serif line-height-some-titles-2 mb-3"
              >{{ homeSingleton.tips_title }}</span
            >
            <span class="d-block continuos-texts-2">{{
              homeSingleton.tips_description
            }}</span>
          </div>
        </v-col>
      </v-row>

      <v-row class="row-news mt-5">
        <v-col cols="12" class="position-relative pa-0">
          <v-carousel
            v-model="carouselNews"
            v-bind:height="heightCarouselNews"
            v-bind:show-arrows="false"
            hide-delimiters
            touchless
          >
            <v-carousel-item v-for="(newsImg, ni) in news" v-bind:key="ni">
              <template v-if="newsImg.cover != null">
                <router-link :to="'/post/' + newsImg.url">
                  <v-img
                    class=""
                    :height="heightCarouselNews"
                    :src="
                      'https://www.cymcms.actstudio.xyz/' + newsImg.cover.path
                    "
                  ></v-img>
                </router-link>
              </template>
            </v-carousel-item>
          </v-carousel>

          <v-carousel
            v-model="carouselNewsText"
            v-bind:height="heightCarouselNewsT"
            v-bind:show-arrows="false"
            hide-delimiters
            class="row-news__blue-div d-none d-sm-flex"
          >
            <v-carousel-item v-for="(newsImg, ni) in news" v-bind:key="ni">
              <router-link
                :to="'/post/' + newsImg.url"
                class="not-text-decoration"
              >
                <v-card color="#143748" width="100%" tile elevation="0">
                  <v-row
                    no-gutters
                    align="center"
                    :style="'height:' + heightCarouselNewsT + 'px;'"
                  >
                    <v-col cols="auto" class="px-12">
                      <div class="row-news__blue-div__description">
                        <span
                          class="bree-serif titles-big-x color-cream line-height-some-titles"
                        >
                          {{ newsImg.title }}
                        </span>
                        <p class="white--text mt-6 continuos-texts-small">
                          {{ newsImg.description }}
                        </p>
                      </div>
                    </v-col>
                  </v-row>
                </v-card>
              </router-link>
            </v-carousel-item>

            <!-- arrows -->
            <div
              class="arrow-right in-home-news d-none d-sm-block"
              @click="carouselNewsText++, carouselNews++"
            >
              <v-img
                src="@/assets/icons/arrow-slider.svg"
                class="arrow-right-img"
              ></v-img>
            </div>
            <!-- /arrows -->
          </v-carousel>
        </v-col>
        <v-col class="d-sm-none pa-0">
          <v-carousel
            v-model="carouselNewsText"
            v-bind:height="heightCarouselNewsT"
            v-bind:show-arrows="false"
            hide-delimiters
            class="row-news__blue-div"
          >
            <v-carousel-item v-for="(newsImg, ni) in news" v-bind:key="ni">
              <router-link
                :to="'/post/' + newsImg.url"
                class="not-text-decoration"
              >
                <v-card color="#143748" width="100%" tile elevation="0">
                  <v-row
                    no-gutters
                    align="center"
                    :style="'height:' + heightCarouselNewsT + 'px;'"
                  >
                    <v-col cols="auto" class="px-12">
                      <div class="row-news__blue-div__description">
                        <span
                          class="bree-serif titles-big-x color-cream line-height-some-titles"
                        >
                          {{ newsImg.title }}
                        </span>
                        <p class="white--text mt-6 continuos-texts-small">
                          {{ newsImg.description }}
                        </p>
                      </div>
                    </v-col>
                  </v-row>
                </v-card>
              </router-link>
            </v-carousel-item>

            <!-- arrows -->
            <div
              class="arrow-right in-home-news"
              @click="carouselNewsText++, carouselNews++"
            >
              <v-img
                src="@/assets/icons/arrow-slider.svg"
                class="arrow-right-img"
              ></v-img>
            </div>
            <!-- /arrows -->
          </v-carousel>
        </v-col>
      </v-row>

      <!-- **********inicia stores********** -->
      <!-- <v-row class="mt-12 Stores" justify="center">
        <v-col cols="12">
          <div class="text-center stores-title">
            <span class="titles-big">{{ homeSingleton.stores_title }}</span>
          </div>
        </v-col> -->

      <!-- <Stores></Stores> -->
      <!-- </v-row> -->
      <!-- **********/termina stores********** -->
    </v-container>

    <!-- contact-about //no moverle -->
    <ContactUs></ContactUs>
  </div>
</template>

<script>
// @ is an alias to /src
import { Splide, SplideSlide } from "@splidejs/vue-splide";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import Stores from "../components/Globals/Stores";
import ContactUs from "../components/Globals/ContactUs";
import Reviews from "../components/Globals/Reviews.vue";

export default {
  name: "Home",
  components: {
    Splide,
    SplideSlide,
    Stores,
    ContactUs,
    Reviews,
  },
  computed: {
    productsTabs: function () {
      var products = this.products;
      var productsTabs = [];
      var modern = [];
      var transitional = [];
      var classic = [];

      products.filter((pro) => {
        if (pro.style.name == "Modern") modern.push(pro);

        if (pro.style.name == "Transitional") transitional.push(pro);

        if (pro.style.name == "Classic") classic.push(pro);
      });

      for (let index = 0; index < modern.length; index++) {
        const element = modern[index];

        if (index == 0) {
          productsTabs.push(element);
        }
      }

      for (let index = 0; index < transitional.length; index++) {
        const element = transitional[index];

        if (index == 0) {
          productsTabs.push(element);
        }
      }

      for (let index = 0; index < classic.length; index++) {
        const element = classic[index];

        if (index == 0) {
          productsTabs.push(element);
        }
      }

      return productsTabs;
    },
  },
  data() {
    return {
      render: false,
      homeSingleton: {},
      products: [],
      slideProducts: 0,
      pathImg: "",
      styleImg: "Transitional",
      filter: "",
      isPaused: false,
      styles: [],

      //slider
      optionsSlider: {
        type: "fade",
        width: "100vw",
        autoHeight: true,
        arrows: false,
        rewind: true,
        interval: 3000,
        speed: 2000,
        autoplay: true,
        breakpoints: {
          500: {
            height: "100vh",
          },
        },
        pauseOnHover: false
      },

      //news
      news: [],
      heightCarouselNews: 586,
      heightCarouselNewsT: 276,
      carouselNews: 0,
      carouselNewsText: 0,
      styleSelected: 0,

      //stores
      stores: [],
      slideStores: 0,
    };
  },
  beforeMount() {
    //fetch for home´s singleton
    fetch(
      "https://www.cymcms.actstudio.xyz/api/singletons/get/home?token=aa5f1f62f706c89e9dae0ea5e2400a"
    )
      .then((data) => data.json())
      .then((data) => (this.homeSingleton = data))
      .then((data) => (this.render = true));

    //fetch para los productos
    fetch(
      "https://www.cymcms.actstudio.xyz/api/collections/get/products?token=aa5f1f62f706c89e9dae0ea5e2400a",
      {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          filter: {
            outstanding: true,
          },
          sort: { _o: 1 },
          populate: 2,
        }),
      }
    )
      .then((res) => res.json())
      .then((res) => (this.products = res.entries));

    //fetch para styles
    fetch(
      "https://www.cymcms.actstudio.xyz/api/collections/get/style?token=aa5f1f62f706c89e9dae0ea5e2400a",
      {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({}),
      }
    )
      .then((res) => res.json())
      .then((res) => (this.styles = res.entries));

    //fetch para las news
    fetch(
      "https://www.cymcms.actstudio.xyz/api/collections/get/news?token=aa5f1f62f706c89e9dae0ea5e2400a",
      {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          filter: {},
        }),
      }
    )
      .then((res) => res.json())
      .then((res) => (this.news = res.entries));

    //fetch para las tiendas
    fetch(
      "https://www.cymcms.actstudio.xyz/api/collections/get/stores?token=aa5f1f62f706c89e9dae0ea5e2400a",
      {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          filter: {},
        }),
      }
    )
      .then((res) => res.json())
      .then((res) => (this.stores = res.entries));
  },
  mounted() {
    //event onResize
    window.addEventListener("resize", this.onResize, { passive: true });

    //emite el valor para cambiar el valor del logo
    this.$emit("update", 1);

    setInterval(() => {
      if (!this.isPaused) {
        this.changeSlideNextProducts();
      }
    }, 3000);
  },
  beforeDestroy() {
    //event onResize
    if (typeof window !== "undefined") {
      window.addEventListener("resize", this.onResize, { passive: true });
    }
  },
  methods: {
    onResize: function (e) {
      // heightCarouselNews
      if (window.innerWidth <= 599) this.heightCarouselNews = 375;
      else if (window.innerWidth >= 600 && window.innerWidth <= 959)
        this.heightCarouselNews = 314;
      else if (window.innerWidth >= 960 && window.innerWidth <= 1263)
        this.heightCarouselNews = 328;
      else if (window.innerWidth >= 1264 && window.innerWidth <= 1903)
        this.heightCarouselNews = 366;
      else if (window.innerWidth >= 1904) this.heightCarouselNews = 586;

      ////////////////heightCarouselNewsT
      if (window.innerWidth <= 599) this.heightCarouselNewsT = 298;
      else if (window.innerWidth >= 600 && window.innerWidth <= 959)
        this.heightCarouselNewsT = 198;
      else if (window.innerWidth >= 960 && window.innerWidth <= 1263)
        this.heightCarouselNewsT = 247;
      else if (window.innerWidth >= 1264 && window.innerWidth <= 1903)
        this.heightCarouselNewsT = 276;
      else if (window.innerWidth >= 1904) this.heightCarouselNewsT = 441;
    },
    changeSlideNextProducts: function () {
      this.slideProducts++;

      if (this.slideProducts >= this.products.length - 1)
        this.slideProducts = 0;
    },
    changeSlidePrevProducts: function () {
      this.slideProducts--;

      if (this.slideProducts <= this.products.length)
        this.slideProducts = this.products.length - 1;
    },
  },
};
</script>

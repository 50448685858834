<template>
  <v-row class="mt-12 mb-md-12 pa-0" id="Reviews">
    <v-col class="text-center">
      <span class="titles-big">Reviews</span>
    </v-col>
    <v-col cols="12" class="px-0 ">
      
          
      <v-slide-group
        v-model="slideReviews"
        v-bind:show-arrows="false"
        center-active
        mandatory
      >
        <v-slide-item
          v-for="(reviews, rw) in singletonHome.reviewsGeneral"
          v-bind:key="rw"
          v-slot="{ active, toggle }"
          
        >
          <v-card
            class="Product__card-reviews"
            @click="toggle"
            v-bind:ripple="false"
            v-bind:class="active ? undefined : 'inactive'"
            elevation="0"
            @mouseover="isPaused=true"
            @mouseleave="isPaused=false"
          >
            <v-row justify="center" align="center" style="height: 100%">
              <v-col cols="auto">
                <v-card
                  class="Product__card-reviews--card-rating"
                  elevation="0"
                >
                  <v-row
                    align="center"
                    justify="center"
                    no-gutters
                    style="height: 100%"
                  >
                    <v-col cols="auto" class="text-center">
                      <span class="d-block"
                        >{{ reviews.stars }} star<template
                          v-if="reviews.stars > 1"
                          >s</template
                        ></span
                      >

                      <v-img
                        src="@/assets/icons/star.svg"
                        class="d-inline-block mt-2 mr-1 img-stars"
                        v-for="(star, s) in reviews.stars"
                        v-bind:key="s"
                        
                      ></v-img>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>

              <v-col cols="auto">
                <div class="Product__card-reviews--column-right">
                  <span class="continuos-texts-xxs-small review-date">{{reviews.date}}</span>
                  <span class="continuos-texts-2 d-block">{{reviews.comment}}</span>
                  <span class="continuos-texts-small d-block">{{reviews.comment_detail}}</span>
                  <span class="d-inline-block mt-8 continuos-texts-xxs-small">By {{ reviews.written_by }}</span>
                  <span class="d-inline-block continuos-texts-8 ml-2">{{reviews.verified}}</span>
                  <span class="d-block">From {{ reviews.from }}</span>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-slide-item>
      </v-slide-group>
    </v-col>

    <v-col class="d-sm-none position-relative pt-16" cols="12">
      <div class="div-dots-reviews">
        <div
          v-for="(dot, dt) in singletonHome.reviewsGeneral"
          v-bind:key="dt"
          v-bind:class="{ 'active-dot': dt == slideReviews }"
          @click="slideReviews = dt"
          class="div-dots-reviews__dot"
        ></div>
      </div>
    </v-col>
  </v-row>
</template>
<script>
export default {
  data() {
    return {
      singletonHome: {},
      slideReviews: 1,
      isPaused:false,
      
      
    };
  },
  beforeCreate(){

    fetch(
      "https://www.cymcms.actstudio.xyz/api/singletons/get/home?token=aa5f1f62f706c89e9dae0ea5e2400a",
      {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          
          populate: 1, // resolve linked collection items
        }),
      }
    )
      .then((res) => res.json())
      .then((res) => (this.singletonHome = res));


  },
  mounted() {
      setInterval(()=>{
        if(!this.isPaused)
          this.slideReviews++;
      },3000)
  },
};
</script>